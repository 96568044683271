/**
 * Objeto para exportar todas las actions del store,
 * aquí se cargan y/o agregan todas las actions necesarias
 * para el store.
 */

import {
    getPermisos,
    getUsuarios,
    getRoles,
    getSucursales,
    getCiudades,
    getRazones,
    getPuestos,
    getHorarios,
    getEmpleados,
    getEmpleadosBySearch,
    getMovimientos,
    getMovimientosFil,
} from './actionMethods/get';

import {
    login,
    postUsuarios,
    postRoles,
    postSucursales,
    postPuestos,
    postHorarios,
    postEmpleados,
    postRazones,
} from './actionMethods/post';

import {
    putUsuarios,
    putRoles,
    putSucursales,
    putSucursalesApi,
    putPuestos,
    putHorarios,
    putEmpleados,
    putRazones,
} from './actionMethods/put';

import {
    deleteUsuarios,
    deleteRoles,
    deleteSucursales,
    deletePuestos,
    deleteHorarios,
    deleteEmpleados,
    deleteRazones,
} from './actionMethods/delete';

// Comisiones
import { getConfig, putConfig } from '../comisiones/store/config'
import {
    postReporteEmpleado,
    postReporteEmpleadoPublic,
    postReporteEmpresa,
} from '../comisiones/store/reportes'
import {
    deleteComisionesEmpleado,
    getComisionesEmpleado,
    postComisionesEmpleado
} from '../comisiones/store/comisiones_empleado';

import router from '@/router';
import store from '@/store';

export default {

    // GET
    getPermisos,
    getUsuarios,
    getRoles,
    getRazones,
    getSucursales,
    getEmpleadosBySearch,
    getCiudades,
    getPuestos,
    getHorarios,
    getEmpleados,
    getMovimientos,
    getMovimientosFil,
    
    // comisiones
    getConfig,
    postReporteEmpleado,
    postReporteEmpresa,
    postReporteEmpleadoPublic,
    getComisionesEmpleado,
    deleteComisionesEmpleado,
    postComisionesEmpleado,

    // POST
    login,
    postUsuarios,
    postRoles,
    postSucursales,
    postPuestos,
    postHorarios,
    postEmpleados,
    postRazones,

    // PUT
    putUsuarios,
    putRoles,
    putSucursales,
    putSucursalesApi,
    putPuestos,
    putHorarios,
    putEmpleados,
    putRazones,
    putConfig,

    // DELETE
    deleteUsuarios,
    deleteRoles,
    deleteSucursales,
    deletePuestos,
    deleteHorarios,
    deleteEmpleados,
    deleteRazones,

    noAuth() {
        console.log('not auth');
        console.log(sessionStorage.getItem('userdata'));
        console.log(store.state.userdata);

        // if (store.state.userdata) {
        //     console.warn('Se encontro informacion de usuario');
        //     return;
        // }
        router.push({ name: "login" });
    },

    reload() {
        router.go();
    }
};