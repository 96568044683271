import axios from "axios";

/**
 * Llama al endpoint para obtener al empleado y la forma en la cual obtiene sus comisiones
 * @param {*} context 
 * @param {*} payload 
 * @returns 
 */
export async function getComisionesEmpleado(context, payload) {
   try {
      const res = await axios.post('comisiones/empleado_get', payload);
      return res;
   } catch (error) {
      console.log(error);
      return error;
   }
}

/**
 * Crea o actualiza la forma de evaluar las comisiones del empleado
 * @param {*} context 
 * @param {*} payload 
 * @returns 
 */
export async function postComisionesEmpleado(context, payload) {
   try {
      const res = await axios.post('comisiones/empleado', payload);
      return res;
   } catch (error) {
      console.log(error);
      return error;
   }
}

/**
 * Elimina las comisiones del empleado seleccionado
 * @param {*} context 
 * @param {*} payload 
 * @returns 
 */
export async function deleteComisionesEmpleado(context, payload) {
   try {
      const res = await axios.delete('comisiones/empleado', {
         data: payload
      });
      return res;
   } catch (error) {
      console.log(error);
      return error;
   }
}