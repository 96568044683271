import permisos from '../../../backend/permisos.json';
import mutationsComisiones from '../comisiones/store/mutations';

export const getCacheableState = () => {
   return {
      username: "",
      token: "",
      rights: "",
   };
};

export const defaultState = () => {
   return {
      userdata: getCacheableState(),
      empleadoUser : {},
      permisos: Object.keys(permisos),
      usuarios: [],
      roles: [],
      sucursales: [],
      ciudades: [],
      razones: [],
      puestos: [],
      horarios: [],
      empleados: [],
      movimientos: [],
      movimientosAll: [],
      comisionesState: mutationsComisiones.comisionesState,
   };
};