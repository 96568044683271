module.exports = Object.freeze({
    // DEV
    // API_URL: "http://127.0.0.1:8000/api/",
    // APP_URL: "http://192.168.1.222:8080/",
    APP_NAME: "ONE Mobile",

    // DEPLOY
    // API_URL: "https://185.28.23.235/public/api",
    // APP_URL: "https://185.28.23.235/",
    API_URL: "https://onemobile.mx/public/api",
    APP_URL: "https://onemobile.mx/",
    DEVELOP_MODE: false
});