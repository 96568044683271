import Vue from "vue";
import VueRouter from "vue-router";
import permisos from '../../backend/permisos.json';
import store from "./store";

// comisiones router
import comisionesRoutes from "./comisiones/router";

Vue.use(VueRouter);

const routes = [
    // las rutas `/`, `/login` y `dashboard` necesitan estár en este orden para que no haya
    // ninguna clase de error ocultando y mostrando los elementos
    {
        path: '/',
        redirect: '/login',
        meta: {
            require: [],
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import('./views/LoginView.vue'),
        meta: {
            require: [],
            name: "Iniciar sesion",
            icon: "mdi-login",
            show: true,
        }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("./views/DashboardView.vue"),
        meta: {
            require: [permisos.user],
            name: "Dashboard",
            icon: "mdi-home",
            show: true,
        },
    },
    {
        path: "/movimientos",
        name: "movimientos",
        // component: MovimientosView,
        component: () => import('./views/MovimientosView.vue'),
        meta: {
            require: [permisos.get_movimientos],
            name: "Movimientos",
            icon: "mdi-swap-horizontal",
            show: true,
        }
    },
    {
        path: "/empleados",
        name: "empleados",
        component: () => import('./views/EmpleadosView.vue'),
        meta: {
            require: [permisos.get_empleados],
            name: "Empleados",
            icon: "mdi-file-account",
            show: true,
        }
    },
    {
        path: "/razones",
        name: "razones",
        component: () => import("./views/RazonesView.vue"),
        meta: {
            require: [permisos.get_razon_social],
            name: "Razones sociales",
            icon: "mdi-account-group",
            show: true,
        }
    },
    {
        path: "/sucursales",
        name: "sucursales",
        component: () => import('./views/SucursalesView.vue'),
        meta: {
            require: [permisos.get_sucursales],
            name: "Sucursales",
            icon: "mdi-city",
            show: true,
        }
    },
    {
        path: "/puestos",
        name: "puestos",
        component: () => import('./views/PuestosView.vue'),
        meta: {
            require: [permisos.get_puestos],
            name: "Puestos",
            icon: "mdi-account-tie",
            show: true,
        },
    },
    {
        path: "/horarios",
        name: "horarios",
        component: () => import('./views/HorariosView.vue'),
        meta: {
            require: [permisos.get_horarios],
            name: "Horarios",
            icon: "mdi-clock-time-five",
            show: true,
        },
    },
    {
        path: "/roles",
        name: "roles",
        component: () => import('./views/RolesView.vue'),
        meta: {
            require: [permisos.get_roles],
            name: "Roles",
            icon: "mdi-alert-decagram",
            show: true,
        }
    },
    {
        path: "/usuarios",
        name: "users",
        component: () => import('./views/UsuariosView.vue'),
        meta: {
            require: [permisos.get_usuarios],
            name: "Usuarios",
            icon: "mdi-account",
            show: true,
        }
    },

    ...comisionesRoutes,

    // Necesita ser la ultima ruta para ser la ultima evaluada
    {
        path: "*",
        name: "404",
        component: () => import("./views/NotFound.vue"),
        meta: {
            require:[],
            show: false,
        },
    },
];

const router = new VueRouter({
    routes,
});

// Redirects en caso de no estar logeado o no tener los permisos necesarios
const redirectToLogin = () => {
    router.push({
        name: "login",
        query: {
            status: "denied",
            time: new Date().getTime(),
        },
    });
};

const redirectToDashboard = () => {
    router.push({
        name: 'dashboard',
        query: {
            status: "denied",
        }
    }, () => {});
};

const showNavbarItems = routes => {
    if (!sessionStorage.getItem('userdata')) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].meta.require.length <= 0) continue;
                routes[i].meta.show = false;
        }
        return;
    }

    const permisosUsuario = store.state.userdata.rights;

    routes.forEach(item => {
        const permisosNecesarios = item.meta.require;

        item.meta.show = false;

        if (permisosUsuario.includes(permisos.super) || permisosUsuario.includes(...permisosNecesarios))
            item.meta.show = true;

        if (item.meta.isInDashboard !== null && item.meta.isInDashboard !== undefined) {
            item.meta.show = item.meta.isInDashboard;
        }
    });

    // Hide redirect to login
    routes[0].meta.show = false;
    // Hide login
    routes[1].meta.show = false;
    // Hide 404
    routes[routes.length - 1].meta.show = false;
    // Show dashboard
    routes[2].meta.show = true;
};

/**
 * Para encargarse de la autenticacion tanto si escriben la url directa ejemplo `/dashboard`
 * como si navegan por medio de la navbar
 */
router.beforeEach(async function (to, from, next) {
    if (sessionStorage.getItem("userdata")) {
        store.commit('init');
    }

    if (to.name !== "comision") {
        sessionStorage.removeItem("empleadoUser");
        store.state.empleadoUser = {};
    }


    showNavbarItems(routes);

    try {
        // No requiere auth
        if (to.meta.require.length <= 0) {
            next();
            return;
        }

        // Require auth
        const permisosUsuario = store.state.userdata.rights;

        // El usuario no esta logeado o hubo algun problema
        // para cargar sus datos
        if (permisosUsuario.length <= 0) {
            redirectToLogin();
            return;
        }
    
        // En caso de solo estar protegida para los no usuarios
        // Ejemplo el dashboard
        if (to.meta.require.includes(permisos.user)) {
            next();
            return;
        }

        // En caso de tener el permiso de ser administrador
        if (permisosUsuario.includes(permisos.super)) {
            next();
            return;
        }

        // Para todos los usuarios que no son admin
        const permisosRuta = to.meta.require;

        if (!permisosUsuario.includes(...permisosRuta)) {
            redirectToDashboard();
            return;
        }

        next();
    } catch(error) {
        next(error);
    }
});

export default router;