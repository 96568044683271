<template>
    <v-snackbar v-if="!useFunctions"
        v-model="snackbar" :timeout="timeout"
        :color="color"
    >
        {{ text }}
        <template #action="{ attrs }">
            <v-btn
                @click="Close"
                :color="colorBtn"
                text
                v-bind="attrs"
            >
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-else v-model="snackbar" :timeout="timeoutData" :color="colorData">
        {{ textData }}
        <template #action="{ attrs }">
            <v-btn
                @click="Close"
                :color="colorBtnData"
                text
                v-bind="attrs"
            >
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        /**
         * El texto a usar cuando no se usa la prop `use-functions`
         */
        text: String,
        /**
         * El timeout del snackbar
         */
        timeout: {
            type: Number,
            default: 2000,
        },
        /**
         * El color del snackbar
         */
        color: {
            type: String,
            default: "primary",
        },
        /**
         * el color del boton el cual es de tipo text
         */
        colorBtn: {
            type: String,
            default: "white",
        },
        /**
         * Para indicar que se usaran los metodos del componente en lugar de las props
         */
        useFunctions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            snackbar: false,
            colorData: "",
            colorBtnData: "",
            textData: "",
            timeoutData: 2000,
        };
    },
    methods: {
        Show() {
            this.snackbar = true;
        },
        Close() {
            this.snackbar = false;
        },
        Error(text = "") {
            this.colorData = "red";
            this.textData = text;
            this.snackbar = true;
        },
        Success(text = "") {
            this.colorData = "primary";
            this.textData = text;
            this.snackbar = true;
        }
    }
};
</script>

<docs lang="md">
Un sencillo snackbar para usar como alerta en casos donde el componente `AlertVue` sea poco
util
</docs>