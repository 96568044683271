<template>
  <v-alert 
    outlined text :type="alertType" v-model="show"
    dismissible transition="slide-x-transition"
    v-if="!useFunctions"
  >
    {{ text }}
  </v-alert>
  <v-alert v-else outlined text :type="alertTypeData" v-model="show"
    dismissible transition="slide-x-transition">
    {{ textData }}
  </v-alert>
</template>

<script>
export default {
  props: {
    /**
     * Coloca el tipo de alerta que es
     */
    alertType: String,
    /**
     * El texto para usar para la alerta en caso de no estar
     * usando funciones
     */
    text: String,
    /**
     * Propiedad para indicar que se usaran los metodos para
     * llamar a la alerta
     */
    useFunctions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
      textData: "",
      alertTypeData: "info"
    };
  },
  methods: {
    /**
     * Muestra la alerta
     */
    Show() {
      this.show = true;
    },
    /**
     * Muestra una alerta de tipo `success`
     * @param { String } text - El texto de la alerta
     */
    Success(text = "") {
      this.alertTypeData = "success";
      this.textData = text;
      this.show = true;
    },
    Info(text = "") {
      this.alertTypeData = "info";
      this.textData = text;
      this.show = true;
    },
    Error(text = "") {
      this.alertTypeData = "error";
      this.textData = text;
      this.show = true;
    },
    Warning(text = "") {
      this.alertTypeData = "warning";
      this.textData = text;
      this.show = true;
    }
  }
};
</script>

<docs lang="md">
Alerta para usar en toda la aplicacion.
> Se registro como un componente dentro de la instancia de vue.
> No es necesario importarlo
</docs>