/**
 * Todas las actions de tipo post de la aplicacion, aqui no hay, ni deben ir
 * otras acciones que no sean de post
 */

import axios from 'axios';
import { handleError } from './helpers';

/**
 * Para iniciar sesion en la aplicacion, configura axios para
 * incluir el API-KEY en todas las request siguientes
 * @param {*} context 
 * @param { Object } payload - El objeto que tiene todos los datos para iniciar sesion
 * @param { String } payload.username - El nombre del usuario
 * @param { String } payload.password - El password del usuario 
 * @returns { AxiosResponse | AxiosError } 
 */
export async function login(context, payload) {
    try {
        const res = await axios.post("login", payload);
        if (res.data?.user) {
            context.commit("empleadoUser", res.data);
            return res;
        }
        context.commit("login", res.data);
        axios.defaults.headers.common["API-KEY"] = context.state.userdata.token;
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Para crear usuarios,
 * @param {*} context 
 * @param { Object } payload - El objeto con la informacion necesaria para crear al usuario
 * @param { int } payload.rol_id - El id del rol asignado al usuario
 * @param { String } payload.username - El nombre del usuario
 * @param { String } payload.password - El password del usuario
 * @returns { AxiosResponse | AxiosError }
 */
export async function postUsuarios(context, payload) {
    try {
        const res = await axios.post("users", payload);
        await context.dispatch("getUsuarios");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza los roles
 * @param {*} context 
 * @param { Object } payload - donde se pasan los parametros del rol
 * @param { String } payload.nombre - El nombre del rol
 * @param { int[] } payload.permisos - Un arreglo de enteros con los permisos del rol
 * @returns { AxiosResponse | AxiosError }
 */
export async function postRoles(context, payload) {
    try {
        const res = await axios.post("roles", payload);
        await context.dispatch('getRoles');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Crea sucursales
 * @async
 * @param {*} context 
 * @param { Object } payload 
 * @param { String } payload.nombre
 * @returns 
 */
export async function postSucursales(context, payload) {
    try {
        const res = await axios.post("sucursales", payload);
        await context.dispatch('getSucursales');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Crea puestos
 * @param {*} context 
 * @param { Object } payload 
 * @param { String } payload.nombre 
 * @returns { AxiosResponse | AxiosError }
 */
export async function postPuestos(context, payload) {
    try {
        const res = await axios.post("puestos", payload);
        await context.dispatch("getPuestos");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

export async function postRazones(context, payload) {
    try {
        const res = await axios.post("razon_social", payload);
        await context.dispatch("getRazones");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Crea horarios, en el parametro `tiempos` espera un total de
 * 7 elementos. La semana se toma de Lunes a Domingo
 * es decir Lunes(1), Martes(2) ... Domingo (7)
 * @param {*} context 
 * @param { Object } payload
 * @param { String } payload.nombre
 * @param { Object[] } payload.tiempos
 * @param { int } payload.tiempos.dia
 * @param { String } payload.tiempos.es_horario_corrido
 * @param { String } payload.tiempos.es_dia_descanso
 * @param { String } payload.tiempos.entrada
 * @param { String } payload.tiempos.salida_descanso
 * @param { String } payload.tiempos.entrada_descanso
 * @param { String } payload.tiempos.salida
 *  
 * @returns { AxiosResponse | AxiosError }
 */
export async function postHorarios(context, payload) {
    try {
        const res = await axios.post("horarios", payload);
        await context.dispatch('getHorarios');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Crea empleados
 * @param {*} context 
 * @param { Object } payload 
 * @param { String } payload.nombre
 * @param { int } payload.sucursal_id
 * @param { int } payload.puesto_id
 * @param { int } payload.horario_id
 * @param { int } payload.num_empleado
 * @returns { AxiosResponse | AxiosError }
 */
export async function postEmpleados(context, payload) {
    try {
        const res = await axios.post("empleados", payload);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}