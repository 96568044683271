/**
 * Todas las actions de tipo put de la aplicacion, aqui no hay, ni deben ir
 * otras acciones que no sean de put
 */

import axios from "axios";
import { handleError } from "./helpers";

/**
 * Actualiza usuarios. El parametro `estatus` debe ser `S` o `N`
 * @param {*} context
 * @param { Object } payload
 * @param { int } payload.usuario_id
 * @param { int } payload.rol_id
 * @param { String } payload.username
 * @param { String } payload.password
 * @param { String } payload.estatus
 * @returns { AxiosResponse | AxiosError }
 */
export async function putUsuarios(context, payload) {
    try {
        const res = await axios.put("users", payload);
        await context.dispatch("getUsuarios");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza los roles
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.rol_id
 * @param { String } payload.nombre
 * @param { int[] } payload.permisos
 * @returns { AxiosResponse | AxiosError }
 */
export async function putRoles(context, payload) {
    try {
        const res = await axios.put("roles", payload);
        await context.dispatch('getRoles');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza las sucursales. **NO ACTUALIZA EL API KEY DE LA SUCURSAL**
 * El parametro `estatus` debe ser `S` o `N`
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.sucursal_id
 * @param { String } payload.nombre
 * @param { String } payload.estatus
 * @returns { AxiosResponse | AxiosError }
 */
export async function putSucursales(context, payload) {
    try {
        const res = await axios.put("sucursales", payload);
        await context.dispatch('getSucursales');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza la api de la sucursal
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.sucursal_id 
 * @returns 
 */
export async function putSucursalesApi(context, payload) {
    try {
        const res = await axios.put("sucursales/apikey", payload);
        await context.dispatch("getSucursales");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza los puestos
 * @param {*} context
 * @param { Object } payload
 * @param { int } payload.puesto_id
 * @param { String } payload.nombre
 * @returns { AxiosResponse | AxiosError}
 */
export async function putPuestos(context, payload) {
    try {
        const res = await axios.put("puestos", payload);
        await context.dispatch("getPuestos");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Igual que el post de horarios solo se agrega el parametro de `horario_id`
 * @param {*} context 
 * @param {*} payload 
 * @returns 
 */
export async function putHorarios(context, payload) {
    try {
        const res = await axios.put("horarios", payload);
        await context.dispatch('getHorarios');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza al empleado indicado
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.empleado_id
 * @param { String } payload.nombre
 * @param { int } payload.sucursal_id
 * @param { int } payload.puesto_id
 * @param { int } payload.horario_id
 * @param { String } payload.estatus
 * @returns 
 */
export async function putEmpleados(context, payload) {
    try {
        const res = await axios.put("empleados", payload);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

export async function putRazones(context, payload) {
    try {
        const res = await axios.put("razon_social", payload);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}