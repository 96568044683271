import axios, { AxiosError } from "axios";
import errors from "../helpers/errors";

const handleError = (error) => {
   const res = {
      status: 400,
      message: "Hubo un problema",
   };
   console.log(error);
   if (error instanceof AxiosError) {
      if (error.code === errors.ERR_NETWORK) {
         res.status = 400;
         res.message = "Hubo un problema con la conexión, por favor intentelo más tarde";
         return res;
      }

      if (error.code === errors.ERR_BAD_REQUEST) {
         res.status = 400;
         res.message = "Hubo un problema con la información mandada";

         if (error.response.data?.data) {
            const data = error.response.data?.data;
            console.log(data);
            // Problema con la razon social
            if (data?.razon_social) {
               res.message = "Hubo un problema con la razon social enviada";
               return res;
            }

            if (data?.general) {
               res.message = data.general;
               return res;
            }
         }

         if (error.response.data?.message === "Debe ser un periodo no mayor a 8 dias") {
            res.message = "No coloque una fecha mayor a 8 dias, en reporte por razon social";
         }

         return res;
      }
   }

   return res;
};

/**
 * Funcion para obtener el reporte del empleado, este metodo es el que llama
 * a los endpoints del modulo de comisiones y no a los de la api externa
 * @param {*} context 
 * @param {*} payload 
 * @returns 
 */
export async function postReporteEmpleado(context, payload) {
   try {
      const res = await axios.post("comisiones/reportes_empleados", payload);
      console.log(`Post Reporte EMpleado`)
      console.log(res)
      return res;
   } catch (error) {
      return handleError(error);
   }
}

/**
 * Realiza la consulta del reporte a la api de la propia aplicacion no la externa
 * @param {*} context 
 * @param {*} payload 
 */
export async function postReporteEmpresa(context, payload) {
   try {
      const res = await axios.post("comisiones/reportes_masivos", payload);
      return res;
   } catch (error) {
      return handleError(error);
   }
}

/**
 * Permite a los empleados realizar la consulta de sus 
 * comisiones
 * @param {*} context 
 * @param {*} payload 
 * @returns 
 */
export async function postReporteEmpleadoPublic(context, payload) {
   try {
      const res = await axios.post("comisiones/reportes_empleados_public", payload);
      return res;
   } catch (error) {
      return handleError(error);
   }
}