import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import Vuelidate from 'vuelidate';
import router from './router';
import store from './store';
import env from './env';
import axios from 'axios';

import DialogVue from './components/General/DialogVue.vue';
import AlertVue from './components/General/AlertVue.vue';
import SnackBar from './components/General/SnackBar.vue';

Vue.component('DialogVue', DialogVue);
Vue.component('AlertVue', AlertVue);
Vue.component('SnackBar', SnackBar);

Vue.config.productionTip = false;
Vue.prototype.$env = env;

Vue.use(Vuelidate);

// Axios config

axios.defaults.baseURL = env.API_URL;
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";

store.subscribe((mutation, state) => {
  sessionStorage.setItem("empleadoUser", JSON.stringify(state.empleadoUser));
  sessionStorage.setItem("userdata", JSON.stringify(state.userdata));
});

const vueApp = new Vue({
  vuetify,
  router,
  store,
  beforeCreate: async () => {
    store.commit('init');
    // await store.dispatch("getPermisos");
  },
  render: h => h(App)
})

vueApp.$mount('#app');

export default vueApp;