import axios from "axios";

export async function getConfig(context) {
   try {
      const res = await axios.get("comisiones/config");
      context.commit("comisionesConfig", res.data?.data ?? []);
      return res;
   } catch (error) {
      return error;
   }
}

export async function putConfig(context, payload) {
   try {
      const res = await axios.put("comisiones/config", payload);
      await context.dispatch("getConfig");
      return res;
   } catch (error) {
      return error;
   }
}