import { AxiosError } from 'axios';
import env from '../../env';
import router from '@/router';

/**
 * Imprime el error por consola
 * @param { Error } error 
 * @param {*} context 
 */
const handleError = async (error, context = null) => {
    if (env.DEVELOP_MODE) {
        console.log(error);
        console.log(context);
    }

    if (error instanceof AxiosError) {
        const response = error.response.data?.message;
        if (response === 'La sesión ha expirado')
            return router.push({ name: 'login'})

        if (error.response.status === 401)
            return router.push({ name: 'login'})
    }
}

export {
    handleError,
};