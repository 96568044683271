<template>
    <v-dialog v-model="show" width=unset>
        <v-card>
            <v-card-title class="d-flex justify-center">{{ title }}</v-card-title>
            <v-card-text>
                {{ text }}
                <div class="d-flex justify-end">
                    <v-btn text color="secondary" @click="DialogClose">
                        Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="Ok">
                        Aceptar
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            show: false,
        };
    },
    props: {
        /**
         * Para el titulo del v-card-title
         */
        title: String,
        /**
         * Para el texto de v-card-text
         */
        text: String,
    },
    methods: {
        DialogShow() {
            this.show = true;
        },
        DialogClose() {
            this.show = false;
            /**
             * Para indicar que se pulso el boton de cerrar o cancelar en lugar del
             * de aceptar
             */
            this.$emit("close");
        },
        Ok() {
            /**
             * Para indicar que se pulso el boton de aceptar en lugar del de cancelar
             */
            this.$emit("ok");
        }
    }
};
</script>

<docs lang="md">
Modal para usar en los botones de eliminar o interacciones donde solo se necesite
la confirmacion o cancelacion del usuario
</docs>