/**
 * Todas las actions de tipo get de la aplicacion, aqui no hay, ni deben ir
 * otras acciones que no sean de get
 */

import axios from 'axios';
import { handleError } from './helpers';

/**
 * Obtiene los permisos, se puede ver como una alternativa a importar
 * los permisos del json
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getPermisos(context) {
    try {
        const res = await axios.get("permisos");
        context.commit('permisos', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        
        return error;
    }
}

/**
 * Actualiza los usuarios almacenados en el store
 * @async
 * @param { Vue } context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getUsuarios(context) {
    try {
        const res = await axios.get("users");
        context.commit('usuarios', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza los roles almacenados en el store
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getRoles(context) {
    try {
        const res = await axios.get("roles");
        context.commit('roles', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza las sucursales almacenadas en el store
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getSucursales(context, payload) {
    try {
        const res = await axios.get("sucursales", {
            params: {
                search: payload?.search ?? null,
            }
        });
        context.commit('sucursales', res.data);
        context.dispatch('getCiudades');
        return res;
    } catch (error) {
        console.log(error)
        handleError(error, context);
        return error;
    }
}

/**
 * Obtiene las razones sociales
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getRazones(context) {
    try {
        const res = await axios.get("razon_social");
        context.commit('razones', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Obtiene las ciudades que se encuentran en todas las sucursales.
 * @param {*} context 
 */
export function getCiudades(context) {
    const sucursales = context.state.sucursales;
    const ciudadesExistentes = sucursales.map(e => e.ciudad);
    const ciudades = new Set(ciudadesExistentes);

    context.commit('ciudades', ciudades);
}

/**
 * Actualiza los puestos almacenados en el store
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getPuestos(context) {
    try {
        const res = await axios.get("puestos");
        context.commit('puestos', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza los horarios almacenados en el store
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getHorarios(context) {
    try {
        const res = await axios.get("horarios");
        context.commit('horarios', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Actualiza los empleados almacenados en el store
 * @async
 * @param {*} context 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getEmpleados(context, payload) {
    try {
        console.log(payload)
        if (payload.sortDesc === undefined || payload.sortDesc === null) {
            payload.sortDesc = true;
        }
        if (payload.sortBy === undefined || payload.sortBy === null) {
            payload.sortBy = null;
        }
        const res = await axios.get(`empleados`, {
            params: {
                page: payload.page,
                itemsPerPage: payload.itemsPerPage,
                sortDesc: payload.sortDesc,
                search: payload.search,
                sortBy: payload.sortBy ?? null,
            },
        });
        context.commit('empleados', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

export async function getEmpleadosBySearch(context, payload) {
    try {
        const res = await axios.get('empleados_search', {
            params: {
                search: payload.search,
            }
        });
        return res.data;
    } catch (error) {
        console.log(error);
        handleError(error);
        return [];
    }
}

/**
 * Actualiza los movimientos almacenados en el store
 * @async
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.page - El # de pagina que se va a consultar
 * @returns { AxiosResponse | AxiosError }
 * @throws Error 
 */
export async function getMovimientos(context, payload) {
    try {
        if (payload.sortDesc === undefined || payload.sortDesc === null)
            payload.sortDesc = true;

        const res = await axios.get(`movimientos`, {
            params: {
                page: payload.page,
                itemsPerPage: payload.itemsPerPage,
                sortDesc: payload.sortDesc,
            }
        });
        context.commit('movimientos', res.data);
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}


/**
 * Para filtrar los movimientos que se utilizaran para crear el excel y el pdf
 * @async
 * @param {*} context 
 * @param {*} payload 
 * @returns { AxiosResponse | AxiosError }
 */
export async function getMovimientosFil(context, payload) {
    try {
        const res = await axios.get('movimientos/periodo', {
            params: {
                init: payload.init,
                end: payload.end,
                sucursal_id: payload.sucursal_id ?? -1,
                ciudad: payload.ciudad ?? "",
                razon: payload.razon
            },
        });
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}