/**
 * Mutaciones y estado del modulo de comisiones, este sera pasado al principal
 */

export default {
   comisionesState: {
      config: [],
   },
   mutations: {
      comisionesConfig(state, payload) {
         state.comisionesState.config = payload;
      },
   },
}