<template>
    <div>
        <v-app-bar app>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="is_logged"></v-app-bar-nav-icon>
            <v-app-bar-title>
                <v-btn elevation="0" @click="$router.push({ name: 'dashboard' }, () => {});">
                    {{ $env.APP_NAME }}
                </v-btn>
            </v-app-bar-title>
            <div v-if="is_logged" class="align-right">
                <v-hover v-slot="{ hover }">
                    <v-btn icon @click="
                        drawer = false;
                        $store.commit('logout');
                        $router.options.routes[0].meta.show = false;
                        $router.options.routes[1].meta.show = true;
                        $router.push({ name: 'login' }, () => {});
                    " :style="{ 'transform' : hover ? 'scale(1.1)' : 'scale(1)' }">
                        <v-icon>mdi-exit-to-app</v-icon>
                    </v-btn>
                </v-hover>
            </div>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" fixed expand-on-hover v-if="is_logged">
            <v-list nav dense>
                <v-list-item-group v-model="group" active-class="selected-link">
                    <div v-for="(item, index) in $router.options.routes" :key="index" class="my-2">
                        <v-list-item @click="$router.push({ name: item.name }, () => {})" v-if="item.meta.show"
                        >
                            <v-list-item-icon>
                                <v-icon dense>
                                    {{ item.meta.icon }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.meta.name }}</v-list-item-title>
                        </v-list-item>
                    </div>
                </v-list-item-group>
            </v-list>
            <!-- Append -->
            <template #append>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon large>mdi-theme-light-dark</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="px-4">
                            <v-switch inset v-model="dark"></v-switch>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            group: null,
            dark: false,
        };
    },
    watch: {
        /**
         * Para actualizar el tema de la aplicacion y guardarlo en la configuracion
         * del localeStore
         */
        dark() {
            this.$vuetify.theme.dark = this.dark;
            localStorage.setItem("dark", JSON.stringify(this.dark));
        }
    },
    computed: {
        /**
         * Para ver/ocultar el boton de cerrar sesion además de permitir
         * o impedir abrir la barra de navegacion
         */
        is_logged: function () {
            if (this.$store.state.userdata.token)
                return this.$store.state.userdata.token.length > 0;
            return false;
        },
    },
    /**
     * Configura el tema de la aplicacion
     */
    created() {
        this.dark = this.$vuetify.theme.dark;
    },
};
</script>

<style scoped>
.align-right {
    margin: 0 0 0 auto;
}

.selected-link {
    background: #00c6ff;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0072ff, #00c6ff);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0072ff, #00c6ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: white;
}

</style>

<docs lang="md">
La barra de navegacion de la aplicacion
</docs>