/**
 * Todas las actions de tipo delete de la aplicacion, aqui no hay, ni deben ir
 * otras acciones que no sean de delete
 */
import axios from "axios";
import { handleError } from "./helpers";

/**
 * Elimina al usuario indicado
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.usuario_id
 * @returns { AxiosResponse | AxiosError }
 */
export async function deleteUsuarios(context, payload) {
    try {
        const res = await axios.delete("users", {
            data: {
                usuario_id: payload.usuario_id,
            }
        });
        await context.dispatch("getUsuarios");
        return res;
    } catch (error) {
        handleError(error, context);
        return error
    }
}

/**
 * Elimina el rol indicado
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.rol_id
 * @returns { AxiosResponse | AxiosError }
 */
export async function deleteRoles(context, payload) {
    try {
        const res = await axios.delete("roles", {
            data: {
                rol_id: payload.rol_id,
            }
        });
        await context.dispatch("getRoles");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Elimina la sucursal indicada
 * @param {*} context 
 * @param { Object } payload 
 * @param { int } payload.sucursal_id
 * @returns { AxiosResponse | AxiosError }
 */
export async function deleteSucursales(context, payload) {
    try {
        const res = await axios.delete("sucursales", {
            data: {
                sucursal_id: payload.sucursal_id,
            }
        });
        await context.dispatch("getSucursales");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Elimina el puesto indicado
 * @param {*} context 
 * @param { Object } payload
 * @param { int } payload.puesto_id
 * @returns { AxiosResponse | AxiosError }
 */
export async function deletePuestos(context, payload) {
    try {
        const res = await axios.delete("puestos", {
            data: {
                puesto_id: payload.puesto_id,
            },
        });
        await context.dispatch("getPuestos");
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Elimina el horario indicado
 * @param {*} context 
 * @param { Object } payload 
 * @param { int } payload.horario_id 
 * @returns { AxiosResponse | AxiosError }
 */
export async function deleteHorarios(context, payload) {
    try {
        const res = await axios.delete("horarios", {
            data: {
                horario_id: payload.horario_id,
            },
        });
        await context.dispatch('getHorarios');
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

/**
 * Elimina al empleado indicado
 * @param {*} context 
 * @param { Object } payload 
 * @param { int } payload.empleado_id 
 * @returns 
 */
export async function deleteEmpleados(context, payload) {
    try {
        const res = await axios.delete("empleados", {
            data: {
                empleado_id: payload.empleado_id,
            },
        });
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}

export async function deleteRazones(context, payload) {
    try {
        const res = await axios.delete("razon_social", {
            data: {
                id: payload.razon_id,
            }
        });
        return res;
    } catch (error) {
        handleError(error, context);
        return error;
    }
}