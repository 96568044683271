import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

let isDark = false;
if (localStorage.getItem("dark")) {
    isDark = JSON.parse(localStorage.getItem("dark"));
}

export default new Vuetify({
    theme: {
        dark: isDark,
    }
});
