import permisos from '../../../backend/permisos.json';

/**
 * Rutas que SOLO pertenecen al modulo de comisones, la propiedad
 * `isInDashboard` de `meta` es para que lo muestre o no en la barra
 * de navegacion
 */

export default [
   // Empleado
   {
      path: '/comision',
      name: 'comision',
      component: () => import('./views/EmpleadoComisionView.vue'),
      meta: {
         require: [],
         name: "Comision Empleado",
         icon: "mdi-home",
         show: false,
         isInDashboard: false,
      }
   },
   // Panel administrativo
   {
      path: '/comisiones',
      name: 'comisiones',
      component: () => import('./views/ComisionView.vue'),
      meta: {
         require: [permisos.get_comisiones],
         name: "Comisiones",
         icon: "mdi-currency-usd",
         show: true,
         isInDashboard: true,
      },
   },
   {
      path: '/comisiones/empleados',
      name: 'comisiones_empleados',
      component: () => import('./views/ComisionesEmpleadoView.vue'),
      meta: {
         require: [],
         name: "Reportes",
         icon: "mdi-currency-usd",
         show: false,
         isInDashboard: false,
      },
   },
   {
      path: '/comisiones/reportes',
      name: 'reportes',
      component: () => import('./views/ReportesView.vue'),
      meta: {
         require: [permisos.get_reportes],
         name: "Reportes",
         icon: "mdi-currency-usd",
         show: false,
         isInDashboard: false,
      },
   },
   {
      path: '/comisiones/configuracion',
      name: 'comisiones_configuracion',
      component: () => import('./views/ConfigView.vue'),
      meta: {
         require: [permisos.get_configuracion_comisiones],
         name: "Configuracion",
         icon: "mdi-currency-usd",
         show: false,
         isInDashboard: false,
      },
   },
];