import Vue from "vue";
import Vuex from "vuex";
import actions from "./store/actions";
import mutations from "./store/mutations";
import {
    defaultState,
} from "./store/defaultState";

Vue.use(Vuex);

export default new Vuex.Store({
    state: defaultState(),
    mutations,
    actions,
})