import axios from "axios";
import {
    defaultState,
} from './defaultState'
import mutationsComisiones from '../comisiones/store/mutations';

export default {
    init(state) {
        let userdata = null;
        
        if (sessionStorage.getItem("empleadoUser")) {
            state.empleadoUser = JSON.parse(sessionStorage.getItem("empleadoUser"));
        }

        if (!sessionStorage.getItem("userdata")) {
            return;
        }
        
        userdata = JSON.parse(sessionStorage.getItem("userdata"));
        
        if (userdata.token) {
            if (userdata.token.length > 0) {
                state.userdata = userdata;
                axios.defaults.headers.common["API-KEY"] = state.userdata.token;
            }
        }
    },
    login(state, payload) {
        state.userdata = payload;
    },
    empleadoUser(state, payload) {
        state.empleadoUser = payload;
    },
    logout() {
        this.replaceState(defaultState());
    },
    permisos(state, payload) {
        state.permisos = payload;
    },
    usuarios(state, payload) {
        state.usuarios = payload;
    },
    roles(state, payload) {
        state.roles = payload;
    },
    sucursales(state, payload) {
        state.sucursales = payload;
    },
    /**
     * Actualiza las ciudades del state del store. En caso de que
     * el payload sea undefined o null las ciudades serán un arreglo
     * vacio
     * @param {*} state 
     * @param { Set<string> | Array } payload 
     */
    ciudades(state, payload) {
        const ciudades = [];
        payload?.forEach(ciudad => ciudades.push(ciudad));
        ciudades.sort();
        state.ciudades = ciudades;
    },
    puestos(state, payload) {
        state.puestos = payload;
    },
    horarios(state, payload) {
        state.horarios = payload;
    },
    empleados(state, payload) {
        state.empleados = payload;
    },
    movimientos(state, payload) {
        state.movimientos = payload;
    },
    movimientosAll(state, payload) {
        state.movimientosAll = payload;
    },
    razones(state, payload) {
        state.razones = payload;
    },

    // Comisiones
    ...mutationsComisiones.mutations,
};